import { GetToday,GetYesterday } from "@/tools/time";

const searchConfig = (searchOptions) => {
  return {
    formItems: [
      {
        type: "date-picker-day",
        prop: "date_start",
        label: "起始日期",
        placeholder: "请选择起始日期",
        initialValue: GetYesterday(),
      },
      {
        type: "date-picker-day",
        prop: "date_end",
        label: "结束日期",
        placeholder: "请选择结束日期",
        initialValue: GetToday(),
      },
      {
        type: "input",
        prop: "store_name ",
        label: "门店名称",
        placeholder: "请输入门店名称",
      },
      {
        type: "input",
        label: "终端ID",
        prop: "device_id",
        placeholder: "请输入终端ID",
      },
      {
        type: "select",
        label: "支付方式",
        prop: "payment_method_id",
        options: searchOptions.payTypeList,
      },
      // {
      //   type: "select",
      //   label: "结算通道",
      //   prop: "payment_channel_id",
      //   options: searchOptions.channelList,
      // },
    ],
  };
};



export default searchConfig;
